import styled from "styled-components"
import {mediaMax} from "../../../helpers/MediaQueries";

interface IStyleProps {
  img1Top?: string
  img1Width?: string
  img2Top?: string
  img2Width?: string
  imgsWidth?: string
  copiesWidth?: string
  img1OnTop?: boolean | undefined
  img1MaxWidth?: string | undefined
  img1TopMob?: string | undefined
  img1WidthMob?: string | undefined
  img2WidthMob?: string | undefined
  img2TopMob?: string | undefined
}

export const Wrapper = styled.div<IStyleProps>`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  ${mediaMax.phoneXL`
    opacity: 1;
    height: auto;
  `}
`

export const FixedWrapper = styled.div<IStyleProps>`
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 13vh 9% 5vh 6.6%;
  position: relative;
  ${mediaMax.phoneXL`
    flex-direction: column-reverse;
    padding: 0;
  `}
  .animation-style {
    opacity: 0;
    transform: translate(20vw, 0px);
    will-change: transform, opacity;
    ${mediaMax.phoneXL`
      opacity: 1;
      transform: translate(0);
    `}
  }
`

export const TeamImgs = styled.div<IStyleProps>`
  width: ${props => props.imgsWidth};
  position: relative;
  ${mediaMax.phoneXL`
    width: 100%;
    min-height: 400px;
    margin: 36px 0 72px;
  `}
  ${mediaMax.phoneLarge`
    min-height: 360px;
  `}
  ${mediaMax.phoneL`
    min-height: 320px;
  `}
  ${mediaMax.phone`
    min-height: 300px;
  `}
  .img-tl {
    position: absolute;
    top: ${props => props.img1Top};
    left: 0;
    width: ${props => props.img1Width};
    max-width: ${props => props.img1MaxWidth};
    z-index: ${props => (props.img1OnTop ? 1 : 0)};
    ${mediaMax.phoneXL`
      position: relative;
      width: ${(props: { img1WidthMob: string; }) => props.img1WidthMob};
      top: ${(props: { img1TopMob: string; }) => props.img1TopMob};
      margin-bottom: ${(props: { img1TopMob: string; }) => props.img1TopMob};
    `}
  }

  .img-overlap {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: #151515;
    ${mediaMax.phoneXL`
      background-color: transparent;
    `}
  }

  .img-tr {
    position: absolute;
    top: ${props => props.img2Top};
    right: 0;
    width: ${props => props.img2Width};
    ${mediaMax.phoneXL`
      width: ${(props: { img2WidthMob: string; }) => props.img2WidthMob};
      top: ${(props: { img2TopMob: string; }) => props.img2TopMob};
    `}
  }
`

export const TeamInfo = styled.div`
  width: 36.5%;
  align-self: center;
  ${mediaMax.phoneXL`
    width: 100%;
    padding: 0 24px;
    h4 {
      font-size: 12px;
      font-weight: 500;
    }
  `}
  p {
    line-height: 22px;
    ${mediaMax.phoneXL`
      line-height: 1.3;
    `}
  }
  h2 {
    padding: 24px 0;
    ${mediaMax.phoneXL`
      padding: 6px 0 20px;
    `}

  }
`
export const TeamQuotation = styled.div`
  padding-top: 13%;
  ${mediaMax.phoneXL`
    padding-top: 24px;
  `}
  h4 {
    font-weight: 300;
  }

  p {
    font-style: italic;
    font-size: 24px;
    line-height: 29px;
    padding: 24px 0;
    ${mediaMax.phoneXL`
      padding: 12px 0;
    `}
  }

  span {
    font-family: "TT Norms", sans-serif;
    font-weight: 300;
    letter-spacing: 0.1em;
    ${mediaMax.phoneXL`
      font-size: 12px;
    `}
  }
`
